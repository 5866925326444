var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "languages" }, [
    _c("div", { staticClass: "languages-search" }, [
      _c(
        "div",
        [
          _c("feather-icon", {
            staticClass: "cursor-pointer ml-4 mr-2",
            attrs: { icon: "SearchIcon" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "languages-search-text" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.language,
              expression: "language",
            },
          ],
          staticClass: "w-full",
          attrs: { type: "text", placeholder: _vm.$t("vue.searchLanguages") },
          domProps: { value: _vm.language },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.language = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "languages-list" },
      _vm._l(_vm.filteredLanguages, function (ln) {
        return _c(
          "div",
          { key: ln.code },
          [
            _c(
              "vs-checkbox",
              {
                staticClass: "checkbox-main",
                model: {
                  value: ln.selected,
                  callback: function ($$v) {
                    _vm.$set(ln, "selected", $$v)
                  },
                  expression: "ln.selected",
                },
              },
              [_vm._v("\n        " + _vm._s(ln.lnFullText) + "\n      ")]
            ),
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "languages-action" }, [
      _c(
        "div",
        { staticClass: "languages-action-save" },
        [
          _c(
            "vs-button",
            { staticClass: "mb-2", on: { click: _vm.saveLanguages } },
            [_vm._v(_vm._s(_vm.$t("vue.save")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "languages-action-cancel" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mb-2",
              attrs: { type: "border" },
              on: { click: _vm.cancel },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }