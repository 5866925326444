var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "translation-ws" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.widgetId,
            expression: "widgetId",
          },
        ],
        attrs: { name: "widgets" },
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.widgetId = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      [
        _c("option", { attrs: { disabled: "", value: "-1", selected: "" } }, [
          _vm._v(_vm._s(_vm.$t("vue.chooseAWidget"))),
        ]),
        _vm._l(_vm.campaignList, function (option) {
          return _c(
            "option",
            { key: option.id, domProps: { value: option.id } },
            [_vm._v(_vm._s(option.name))]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }