var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "translations" }, [
      _c(
        "div",
        { staticClass: "translations--container" },
        _vm._l(_vm.modifiedTranslations, function (t) {
          return _c(
            "div",
            {
              key: t.ln,
              staticClass: "translations--box",
              class: { "translations--box-sticky": t.ln === "en" },
            },
            [
              _c("div", { staticClass: "translations--header" }, [
                _c("div", { staticClass: "translations--header-main" }, [
                  _c("div", { staticClass: "translations--header-main-text" }, [
                    _vm._v(_vm._s(t.lnText)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "translations--header-main-icon",
                      class: {
                        "translations--header-main-icon-extra": t.ln === "de",
                      },
                    },
                    [
                      !t.canBeDeleted || t.ln === "de"
                        ? _c(
                            "div",
                            [
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text: _vm.$t("info.resetTranslations"),
                                    color: "#12598D",
                                    position: "top",
                                  },
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "translations--button",
                                      attrs: { type: "flat" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onShowResetTranslationConfirmation(
                                            t
                                          )
                                        },
                                      },
                                    },
                                    [_c("reset-icon")],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      t.canBeDeleted
                        ? _c(
                            "div",
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "translations--button",
                                  attrs: { color: "#F05541", type: "border" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowDeleteTranslationConfirmation(
                                        t
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("trash-icon", {
                                    attrs: {
                                      width: 16,
                                      height: 16,
                                      color: "#F05541",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "translations--header-sub" }, [
                  _vm._v(
                    "\n            " + _vm._s(t.lnFullText) + "\n          "
                  ),
                ]),
              ]),
              _c(
                "div",
                [
                  _vm.type === "email-translations"
                    ? _c("email-translation-body", {
                        key:
                          _vm.bodyKey +
                          "-" +
                          t.lnText +
                          "-" +
                          _vm.translationUpdateKey,
                        attrs: {
                          name: _vm.type + "-" + t.lnText,
                          t: t,
                          type: _vm.type,
                        },
                      })
                    : _c("translation-body", {
                        key:
                          _vm.bodyKey +
                          "-" +
                          t.lnText +
                          "-" +
                          _vm.translationUpdateKey,
                        attrs: {
                          name: _vm.type + "-" + t.lnText,
                          t: t,
                          type: _vm.type,
                          webConnect: t.webConnect,
                          qrConnect: t.qrConnect,
                          gptConnect: t.gptConnect,
                        },
                      }),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }