var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "translations" },
    [
      _c("div", { staticClass: "vx-row translations-button-add" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mb-8" },
          [
            _c(
              "vs-button",
              {
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-plus",
                  type: "filled",
                },
                on: { click: _vm.onAddLanguage },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("vue.addLanguage")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("vue.deleteTranslation"),
            active: _vm.showDeletePopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showDeletePopup = $event
            },
          },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("vue.confirmQuestion")) + "\n    "
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: {
                click: function ($event) {
                  return _vm.onConfirmDeleteTranslation()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("vue.confirm")) + "\n    ")]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: {
                click: function ($event) {
                  return _vm.onCancelDeleteTranslation()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("vue.resetTranslation"),
            active: _vm.showResetPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showResetPopup = $event
            },
          },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("vue.confirmQuestion")) + "\n    "
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: {
                click: function ($event) {
                  return _vm.onConfirmResetTranslation()
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("vue.confirm")) + "\n    ")]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: {
                click: function ($event) {
                  return _vm.onCancelResetTranslation()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
          ),
        ],
        1
      ),
      _c(
        "connect-modal",
        { attrs: { show: _vm.showLanguageModal, bkcolor: "#F7F7F7" } },
        [
          _c(
            "div",
            {
              staticClass: "ui-cancelled-sub-mollie-close-button",
              staticStyle: { position: "absolute", top: "10px", right: "10px" },
              on: { click: _vm.onCloseLanguageModal },
            },
            [
              _c("close-circle-icon", {
                staticClass:
                  "ui-cancelled-sub-mollie-close-button-icon pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _c("languages", {
            attrs: { showLanguageModal: _vm.showLanguageModal },
            on: {
              savedLanguages: _vm.onCloseLanguageModal,
              closeLanguage: _vm.onCloseLanguageModal,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "vs-tabs",
            [
              _c(
                "vs-tab",
                {
                  attrs: { label: _vm.$t("vue.buttonAndHints") },
                  on: { click: _vm.onShowButtonAndHints },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-text" },
                    [_c("button-and-hints")],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: { label: _vm.$t("features.appointmentBooking") },
                  on: { click: _vm.onShowAppointmentBooking },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-text" },
                    [_c("appointment-booking")],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: { label: _vm.$t("routes.dialog") },
                  on: { click: _vm.onShowWebConnect },
                },
                [_c("div", { staticClass: "tab-text" }, [_c("web-connect")], 1)]
              ),
              _c(
                "vs-tab",
                {
                  attrs: { label: _vm.$t("routes.gptConnect") },
                  on: { click: _vm.onShowGptConnect },
                },
                [_c("div", { staticClass: "tab-text" }, [_c("gpt-connect")], 1)]
              ),
              _c(
                "vs-tab",
                {
                  attrs: { label: _vm.$t("routes.campaigns") },
                  on: { click: _vm.onShowQRConnect },
                },
                [_c("div", { staticClass: "tab-text" }, [_c("qr-connect")], 1)]
              ),
              _c(
                "vs-tab",
                {
                  attrs: { label: _vm.$t("vue.email") },
                  on: { click: _vm.onShowEmailTranslations },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-text" },
                    [_c("email-translations")],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }