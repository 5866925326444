<template>
  <div>
    <div v-if="showQRWidgetSelection">
      <qr-widget-selection />
    </div>

    <translations v-else type="qr-connect" :key="translationUpdateKey" />
  </div>
</template>
<script>
import Translations from './Translations.vue'
import QrWidgetSelection from './QrWidgetSelection.vue'

export default {
  name: 'TranslationsQRConnect',
  components: {
    Translations,
    QrWidgetSelection
  }
}
</script>
<style lang="scss" scoped></style>
