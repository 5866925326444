var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("div", { staticClass: "rte-translation" }, [
        _c("div", { staticClass: "translation-rte" }, [
          _c("div", { staticClass: "translation-rte-container" }, [
            _c("div", { staticClass: "translation-rte-container-item" }, [
              _c(
                "div",
                { staticClass: "translation-rte-container-item--header" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-large",
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.source")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-smail",
                    },
                    [_vm._v("English")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "translation-rte-container-item--box",
                  attrs: { id: "translation-rte-container-item--box--source" },
                },
                _vm._l(_vm.sortedPropertySource, function (data, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass: "translation-rte-container-item--rte",
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("translations." + key)))]),
                      key !== "name" &&
                      _vm.item.translation.limit[key] &&
                      _vm.item.translation.limit[key].type === "editor"
                        ? _c("vs-textarea", {
                            attrs: { disabled: "", height: "150px" },
                            model: {
                              value: _vm.tsource[key],
                              callback: function ($$v) {
                                _vm.$set(_vm.tsource, key, $$v)
                              },
                              expression: "tsource[key]",
                            },
                          })
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { height: "100px", disabled: "" },
                            model: {
                              value: _vm.tsource[key],
                              callback: function ($$v) {
                                _vm.$set(_vm.tsource, key, $$v)
                              },
                              expression: "tsource[key]",
                            },
                          }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "translation-rte-container-item" }, [
              _c(
                "div",
                { staticClass: "translation-rte-container-item--header" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-large",
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.translation")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-smail",
                    },
                    [_vm._v(_vm._s(_vm.selectedLanguage.lnFullText))]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "translation-rte-container-item--box",
                  attrs: {
                    id: "translation-rte-container-item--box--destination",
                  },
                },
                _vm._l(_vm.sortedPropertyDestination, function (t2data, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass: "translation-rte-container-item--rte",
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("translations." + key)))]),
                      key !== "name" &&
                      _vm.item.translation.limit[key] &&
                      _vm.item.translation.limit[key].type === "editor"
                        ? _c("quill-editor", {
                            ref: "quillEditorSource",
                            refInFor: true,
                            attrs: { options: _vm.editorOption },
                            model: {
                              value: _vm.item.translation.data[key],
                              callback: function ($$v) {
                                _vm.$set(_vm.item.translation.data, key, $$v)
                              },
                              expression: "item.translation.data[key]",
                            },
                          })
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              counter: _vm.item.translation.limit[key],
                              height: "100px",
                            },
                            model: {
                              value: _vm.item.translation.data[key],
                              callback: function ($$v) {
                                _vm.$set(_vm.item.translation.data, key, $$v)
                              },
                              expression: "item.translation.data[key]",
                            },
                          }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "translation-rte-action" }, [
          _c(
            "div",
            {
              staticClass:
                "translation-rte-action-btn translation-rte-action-btn--save",
              on: { click: _vm.onSave },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("vue.save")) + "\n    ")]
          ),
          _c(
            "div",
            {
              staticClass:
                "translation-rte-action-btn translation-rte-action-btn--cancel",
              on: { click: _vm.onCancel },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("vue.cancel")) + "\n    ")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }