var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("div", { staticClass: "rte-translation" }, [
        _c("div", { staticClass: "translation-rte" }, [
          _c("div", { staticClass: "translation-rte-container" }, [
            _c("div", { staticClass: "translation-rte-container-item" }, [
              _c(
                "div",
                { staticClass: "translation-rte-container-item--header" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-large",
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.source")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-smail",
                    },
                    [_vm._v("English")]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "translation-rte-container-item--rte" },
                [
                  _vm.item.translation.limit.type === "editor"
                    ? _c("quill-editor", {
                        ref: "quillEditorSource",
                        attrs: { disabled: "", options: _vm.editorOption },
                        model: {
                          value: _vm.tsource,
                          callback: function ($$v) {
                            _vm.tsource = $$v
                          },
                          expression: "tsource",
                        },
                      })
                    : _c("vs-textarea", {
                        attrs: { height: "100px", disabled: "" },
                        model: {
                          value: _vm.tsource,
                          callback: function ($$v) {
                            _vm.tsource = $$v
                          },
                          expression: "tsource",
                        },
                      }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "translation-rte-container-item" }, [
              _c(
                "div",
                { staticClass: "translation-rte-container-item--header" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-large",
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.translation")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "translation-rte-container-item--header-smail",
                    },
                    [_vm._v(_vm._s(_vm.selectedLanguage.lnFullText))]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "translation-rte-container-item--rte" },
                [
                  _vm.item.translation.limit.type === "editor"
                    ? _c("quill-editor", {
                        ref: "quillEditorSource",
                        attrs: { options: _vm.editorOption },
                        model: {
                          value: _vm.item.translation.data,
                          callback: function ($$v) {
                            _vm.$set(_vm.item.translation, "data", $$v)
                          },
                          expression: "item.translation.data",
                        },
                      })
                    : _c("vs-textarea", {
                        attrs: {
                          counter: _vm.item.translation.limit.limit,
                          height: "100px",
                        },
                        model: {
                          value: _vm.item.translation.data,
                          callback: function ($$v) {
                            _vm.$set(_vm.item.translation, "data", $$v)
                          },
                          expression: "item.translation.data",
                        },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "translation-rte-action" }, [
          _c(
            "div",
            {
              staticClass:
                "translation-rte-action-btn translation-rte-action-btn--save",
              on: { click: _vm.onSave },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("vue.save")) + "\n    ")]
          ),
          _c(
            "div",
            {
              staticClass:
                "translation-rte-action-btn translation-rte-action-btn--cancel",
              on: { click: _vm.onCancel },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("vue.cancel")) + "\n    ")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }