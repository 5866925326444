<template>
  <div>
    <div v-if="showGptWidgetSelection">
      <gpt-widget-selection />
    </div>
    <translations v-else type="gpt-connect" :key="translationUpdateKey" />
  </div>
</template>
<script>
import Translations from './Translations.vue'
import GptWidgetSelection from './GptWidgetSelection.vue'

export default {
  name: 'TranslationsWebConnect',
  components: {
    Translations,
    GptWidgetSelection
  }
}
</script>
<style lang="scss" scoped></style>
