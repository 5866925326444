<template>
  <div class="translation-ws">
    <select name="widgets" v-model="widgetId">
      <option disabled value="-1" selected>{{ $t('vue.chooseAWidget') }}</option>
      <option v-for="option in campaignList" :value="option.id" :key="option.id">{{ option.name }}</option>
    </select>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TranslationQrWidgetSelection',
  data() {
    return {
      widgetId: -1
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      campaigns: 'campaigns'
    }),
    campaignList() {
      return this.campaigns
        .filter((x) => x.createdBy === this.activeUserInfo.uid)
        .map((x) => {
          return {
            id: x.id,
            name: x.name
          }
        })
    }
  },
  watch: {
    widgetId() {
      if (this.widgetId) {
        this.updateSelectedQRWidget(this.widgetId)
        this.getSavedQRConnectTranslation()
        this.updateShowQRWidgetSelection(false)
      } else {
        this.updateSelectedQRWidget(null)
        this.updateShowQRWidgetSelection(true)
      }
    }
  },
  mounted() {
    for (const translation of this.translations) {
      translation.qrConnect = null
    }
  }
}
</script>

<style lang="scss" scoped>
.translation-ws {
  padding: 10px;
  select {
    padding: 6px;
    border-radius: 6px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    option {
      background-color: #275D73;
      color: #fff;
    }
    option:disabled {
      background-color: #c7c7c7;
      color: #fff;
    }
  }
}
</style>
