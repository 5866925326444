<template>
  <div>
    <div class="translations">
      <div class="translations--container">
        <div class="translations--box" v-for="t in modifiedTranslations" v-bind:key="t.ln" :class="{ 'translations--box-sticky': t.ln === 'en' }">
          <div class="translations--header">
            <div class="translations--header-main">
              <div class="translations--header-main-text">{{ t.lnText }}</div>
              <div class="translations--header-main-icon" :class="{ 'translations--header-main-icon-extra': t.ln === 'de' }">
                <div v-if="!t.canBeDeleted || t.ln === 'de'">
                  <vx-tooltip :text="$t('info.resetTranslations')" color="#12598D" position="top">
                    <vs-button class="translations--button" type="flat" @click="onShowResetTranslationConfirmation(t)">
                      <reset-icon />
                    </vs-button>
                  </vx-tooltip>
                </div>
                <div v-if="t.canBeDeleted">
                  <vs-button class="translations--button" color="#F05541" type="border" @click="onShowDeleteTranslationConfirmation(t)">
                    <trash-icon :width="16" :height="16" color="#F05541" />
                  </vs-button>
                </div>
              </div>
            </div>
            <div class="translations--header-sub">
              {{ t.lnFullText }}
            </div>
          </div>
          <div>
            <email-translation-body
              v-if="type === 'email-translations'"
              :name="`${type}-${t.lnText}`"
              :t="t"
              :key="`${bodyKey}-${t.lnText}-${translationUpdateKey}`"
              :type="type"
            />
            <translation-body
              v-else
              :name="`${type}-${t.lnText}`"
              :t="t"
              :key="`${bodyKey}-${t.lnText}-${translationUpdateKey}`"
              :type="type"
              :webConnect="t.webConnect"
              :qrConnect="t.qrConnect"
              :gptConnect="t.gptConnect"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import ResetIcon from '@/components/icons/ResetIcon'
import TrashIcon from '@/components/icons/TrashIcon'
import TranslationBody from './TranslationBody.vue'
import EmailTranslationBody from './EmailTranslationBody.vue'
export default {
  name: 'Translation',
  components: {
    ResetIcon,
    TrashIcon,
    TranslationBody,
    EmailTranslationBody
  },
  props: {
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      showResetTranslationPopup: false,
      selectedTransationToReset: null
    }
  },
  computed: {
    modifiedTranslations() {
      if (this.type === 'button-and-hints') {
        const translations = this.translations.filter((x) => x.buttonAndHints || this.defaultTranslations.includes(x.ln))
        for (const translation of translations) {
          if (this.defaultTranslations.includes(translation.ln) && !translation.buttonAndHints) {
            const default_lang = translation.ln === 'de' ? this.default_de : this.default_en
            translation.buttonAndHints = JSON.parse(JSON.stringify(default_lang.buttonAndHints))
            if (translation.ln !== 'de') translation.buttonAndHints.__isCopiedFromDefault = true
          }
        }
        return translations
      }

      if (this.type === 'appointment-booking') {
        const translations = this.translations.filter((x) => x.appointmentBooking || this.defaultTranslations.includes(x.ln))
        for (const translation of translations) {
          if (this.defaultTranslations.includes(translation.ln) && !translation.appointmentBooking) {
            const default_lang = translation.ln === 'de' ? this.default_de : this.default_en
            translation.appointmentBooking = JSON.parse(JSON.stringify(default_lang.appointmentBooking))
            if (translation.ln !== 'de') translation.appointmentBooking.__isCopiedFromDefault = true
          }
        }

        return translations
      }

      if (this.type === 'email-translations') {
        const translations = this.translations.filter((x) => x.emailTranslations || this.defaultTranslations.includes(x.ln))
        for (const translation of translations) {
          if (this.defaultTranslations.includes(translation.ln) && !translation.emailTranslations) {
            const default_lang = translation.ln === 'de' ? this.default_de : this.default_en
            translation.emailTranslations = JSON.parse(JSON.stringify(default_lang.emailTranslations))
            if (translation.ln !== 'de') translation.emailTranslations.__isCopiedFromDefault = true
          }
        }

        return translations
      }

      if (this.type === 'web-connect') {
        return this.translations.filter((x) => x.webConnect !== null)
      }
      if (this.type === 'gpt-connect') {
        return this.translations.filter((x) => x.gptConnect !== null)
      }
      if (this.type === 'qr-connect') {
        return this.translations.filter((x) => x.qrConnect !== null)
      }
      return this.translations
    },
    bodyKey() {
      if (this.type === 'web-connect') {
        return `${this.type}-${this.selectedWidget}`
      }
      if (this.type === 'gpt-connect') {
        return `${this.type}-${this.selectedGptWidget}`
      }
      if (this.type === 'qr-connect') {
        return `${this.type}-${this.selectedQRWidget}`
      }
      return this.type
    }
  },
  methods: {
    ...mapMutations({
      updateTranslationToBeDeleted: 'languages/UPDATE_TRANSLATION_TO_BE_DELETED',
      updateTranslationToBeReseted: 'languages/UPDATE_TRANSLATION_TO_BE_RESETED',
      updateShowDeleteTranslationModal: 'languages/UPDATE_SHOW_DELETE_TRANSLATION_MODAL',
      updateShowResetTranslationModal: 'languages/UPDATE_SHOW_RESET_TRANSLATION_MODAL'
    }),

    onShowDeleteTranslationConfirmation(t) {
      this.updateTranslationToBeDeleted(t)
      this.updateShowDeleteTranslationModal(true)
    },
    onShowResetTranslationConfirmation(t) {
      this.updateTranslationToBeReseted(t)
      this.updateShowResetTranslationModal(true)
    }
  }
}
</script>
<style lang="scss" scoped>
.translations {
  display: flex;
  flex-direction: row;

  overflow-x: auto;
  height: 100%;

  &--button {
    padding: 8px 9px !important;
  }
  &--button:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  }

  &--container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &--box {
    width: 260px;
    height: 497px;
    margin-left: 10px;
    margin-right: 10px;

    border-radius: 6px;

    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

    &-sticky {
      position: sticky;
      left: 0px;
      z-index: 1;
      position: -webkit-sticky;
    }
  }

  &--header {
    background: #f4fbff;
    width: 260px;
    height: 70px;
    padding: 16px;
    display: flex;
    flex-direction: column;

    &-main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
      }

      &-icon {
        height: 17px;
        cursor: pointer;
        display: flex;
        flex-direction: row;

        justify-content: space-between;

        &-extra {
          width: 80px;
        }
      }
    }

    &-sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #b2b2b2;
    }
  }
}
</style>
