var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showPopup
        ? _c(
            "connect-modal",
            { attrs: { show: _vm.showPopup } },
            [
              _c("object-input-popup", {
                attrs: { item: _vm.selectedPopupItem },
                on: {
                  "save-popup": _vm.onSavePopup,
                  "cancel-popup": _vm.onCancelPopup,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          key: "body-box-" + _vm.name + "-" + _vm.translationUpdateKey,
          staticClass: "translations--body",
          attrs: { id: "body-box-" + _vm.name },
        },
        _vm._l(_vm.translatedVersions, function (item, index) {
          return _c(
            "div",
            {
              key:
                "body-box-" +
                _vm.name +
                "_" +
                index +
                "_" +
                _vm.translationUpdateKey,
              staticClass: "translations--body-box",
            },
            [
              _c(
                "div",
                {
                  staticClass: "translations--body-box-item",
                  attrs: {
                    id:
                      "body-box-" +
                      _vm.t.ln +
                      "-" +
                      _vm.type +
                      "-" +
                      _vm.translatedVersions[index].key,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "translations--body-box-item-text" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.translatedVersions[index].name,
                            expression: "translatedVersions[index].name",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id:
                            "input-" +
                            _vm.t.ln +
                            "-" +
                            _vm.type +
                            "-" +
                            _vm.translatedVersions[index].key,
                          placeholder:
                            _vm.placeHolderObject[
                              _vm.translatedVersions[index].key
                            ],
                          disabled:
                            typeof _vm.translatedVersions[index].limit ===
                            "object",
                        },
                        domProps: { value: _vm.translatedVersions[index].name },
                        on: {
                          change: function ($event) {
                            return _vm.onInputChanged(
                              _vm.t.ln +
                                "-" +
                                _vm.type +
                                "-" +
                                _vm.translatedVersions[index].key
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.translatedVersions[index],
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  typeof _vm.translatedVersions[index].limit === "number"
                    ? _c(
                        "div",
                        {
                          key: _vm.updateKey,
                          staticClass: "translations--body-box-item-textcount",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.translatedVersions[index].data.length
                              ) +
                              " / " +
                              _vm._s(_vm.translatedVersions[index].limit) +
                              "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "translations--body-box-item-icon",
                          on: {
                            click: function ($event) {
                              return _vm.onShowPopup(
                                _vm.translatedVersions[index],
                                _vm.t.ln
                              )
                            },
                          },
                        },
                        [_c("arrow-open-icon")],
                        1
                      ),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }