<template>
  <div v-if="item" class="rte-translation">
    <div class="translation-rte">
      <div class="translation-rte-container">
        <div class="translation-rte-container-item">
          <div class="translation-rte-container-item--header">
            <div class="translation-rte-container-item--header-large">{{ $t('vue.source') }}</div>
            <div class="translation-rte-container-item--header-smail">English</div>
          </div>
          <div class="translation-rte-container-item--rte">
            <quill-editor v-if="item.translation.limit.type === 'editor'" disabled v-model="tsource" ref="quillEditorSource" :options="editorOption">
            </quill-editor>
            <vs-textarea v-else height="100px" disabled v-model="tsource" />
          </div>
        </div>
        <div class="translation-rte-container-item">
          <div class="translation-rte-container-item--header">
            <div class="translation-rte-container-item--header-large">{{ $t('vue.translation') }}</div>
            <div class="translation-rte-container-item--header-smail">{{ selectedLanguage.lnFullText }}</div>
          </div>
          <div class="translation-rte-container-item--rte">
            <quill-editor v-if="item.translation.limit.type === 'editor'" v-model="item.translation.data" ref="quillEditorSource" :options="editorOption">
            </quill-editor>
            <vs-textarea :counter="item.translation.limit.limit" v-else height="100px" v-model="item.translation.data" />
          </div>
        </div>
      </div>
    </div>
    <div class="translation-rte-action">
      <div class="translation-rte-action-btn translation-rte-action-btn--save" @click="onSave">
        {{ $t('vue.save') }}
      </div>
      <div class="translation-rte-action-btn translation-rte-action-btn--cancel" @click="onCancel">
        {{ $t('vue.cancel') }}
      </div>
    </div>
  </div>
</template>
<script>
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'RichTextInputPopup',
  components: {
    quillEditor
  },
  data() {
    return {
      tsource: null,
      tdata: null,
      editorOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', { size: ['small', false, 'large', 'huge'] }], ['link'], [{ color: [] }, { background: [] }], ['clean']]
          }
        }
      }
    }
  },
  props: ['item'],

  computed: {
    allLanguages() {
      const langs = this.languages.filter((x) => {
        return {
          code: x.code,
          lnFullText: x.lnFullText
        }
      })
      langs.push({ code: 'en', lnFullText: 'English' })
      langs.push({ code: 'de', lnFullText: 'German' })
      return langs
    },
    selectedLanguage() {
      const language = this.allLanguages.find((x) => x.code === this.item.ln)
      return language || { code: '', lnFullText: '' }
    }
  },

  methods: {
    onSave() {
      if (this.item.translation.limit.type === 'text-area' && this.item.translation.data.length > this.item.translation.limit.limit) {
        return
      }
      this.$emit('save-popup')
    },
    onCancel() {
      const translation = this.item.translation
      translation.data = this.tdata
      this.$emit('cancel-popup')
    }
  },
  watch: {
    'item.translation.key'() {
      if (this.item && this.item.translation) {
        const translation = this.item.translation
        this.tdata = translation.data

        if (translation.key) {
          if (this.item.type === 'button-and-hints') {
            this.tsource = this.default_en.buttonAndHints[translation.key]
          }
          if (this.item.type === 'appointment-booking') {
            this.tsource = this.default_en.appointmentBooking[translation.key]
          }
          if (this.item.type === 'email-translations') {
            this.tsource = this.default_en.emailTranslations[translation.key]
          }
          if (this.item.type === 'web-connect') {
            const tSource =
              this.item.ln === 'en'
                ? this.default_en.webConnect
                : this.selectedWidgetSavedTranslation && this.selectedWidgetSavedTranslation.webConnect
                ? this.selectedWidgetSavedTranslation.webConnect
                : this.default_en.webConnect
            this.tsource = tSource[translation.key]
          }
          if (this.item.type === 'gpt-connect') {
            const tSource =
              this.item.ln === 'en'
                ? this.default_en.gptConnect
                : this.selectedGptWidgetSavedTranslation && this.selectedGptWidgetSavedTranslation.gptConnect
                ? this.selectedGptWidgetSavedTranslation.gptConnect
                : this.default_en.gptConnect
            this.tsource = tSource[translation.key]
          }
          if (this.item.type === 'qr-connect') {
            const tSource =
              this.item.ln === 'en'
                ? this.default_en.qrConnect
                : this.selectedQRWidgetSavedTranslation && this.selectedQRWidgetSavedTranslation.qrConnect
                ? this.selectedQRWidgetSavedTranslation.qrConnect
                : this.default_en.qrConnect
            this.tsource = tSource[translation.key]
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.translations .con-ul-tabs {
  z-index: 1;
}
.translations .modal-container {
  @media only screen and (max-width: 1200px) {
    margin-left: 0px !important;
  }
  @media only screen and (min-width: 1201px) {
    margin-left: 325px !important;
  }
}
</style>
<style lang="scss" scoped>
.rte-translation {
  @media only screen and (min-width: 1601px) {
    width: 1200px;
    height: 400px;
  }

  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 1000px;
    height: 400px;
  }

  @media only screen and (min-width: 1301px) and (max-width: 1400px) {
    width: 900px;
    height: 400px;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1300px) {
    width: 800px;
    height: 400px;
  }

  @media only screen and (max-width: 1200px) {
    width: calc(98vw);
    height: calc(98vh);
  }

  .translation-rte {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1201px) {
      padding: 30px;
    }

    @media only screen and (max-width: 1200px) {
      padding: 15px;
    }

    &-action {
      border: none;
      border-top: 0.25px solid #c4c4c4;

      flex-grow: 1;
      padding: 30px;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      > div {
        margin: 0px 6px;
      }

      :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        font-size: 14px;
      }

      &-btn {
        width: 97.28px;
        height: 34px;
        border-radius: 6px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &--save {
          background: #01aaf4;
          color: #fff;
        }

        &--cancel {
          color: #01aaf4;
        }
      }
    }

    &-container {
      display: flex;
      @media only screen and (min-width: 1201px) {
        flex-direction: row;
        justify-content: space-between;
      }
      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: calc(82vh);
      }

      &-item {
        background: #ffffff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

        @media only screen and (min-width: 1601px) {
          width: 560px;
          height: 246px;
        }

        @media only screen and (min-width: 1401px) and (max-width: 1600px) {
          width: 460px;
          height: 246px;
        }

        @media only screen and (min-width: 1301px) and (max-width: 1400px) {
          width: 410px;
          height: 246px;
        }

        @media only screen and (min-width: 1201px) and (max-width: 1300px) {
          width: 360px;
          height: 246px;
        }

        @media only screen and (max-width: 1200px) {
          width: 100%;
          height: calc(40vh);
        }

        display: flex;
        flex-direction: column;

        &--header {
          background: #f4fbff;
          height: 70px;
          padding: 20px 20px 10px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &-large {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 15px;
            letter-spacing: -0.177778px;
            color: #262629;
            text-transform: uppercase;
          }
          &-small {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.177778px;
            color: #b2b2b2;
          }
        }
        &--rte {
          background: #fff;
          padding: 13px 18px 13px 11px;
          flex-grow: 1;
          .quill-editor {
            @media only screen and (min-width: 1201px) {
              height: 108px;
              max-height: 108px;
            }

            @media only screen and (max-width: 1200px) {
              height: calc(24vh);
              max-height: calc(24vh);
            }
          }
        }
      }
    }
  }
}
</style>
