<template>
  <div class="languages">
    <div class="languages-search">
      <div><feather-icon icon="SearchIcon" class="cursor-pointer ml-4 mr-2" /></div>
      <div class="languages-search-text">
        <input type="text" class="w-full" v-model="language" :placeholder="$t('vue.searchLanguages')" />
      </div>
    </div>
    <div class="languages-list">
      <div v-for="ln in filteredLanguages" :key="ln.code">
        <vs-checkbox v-model="ln.selected" class="checkbox-main">
          {{ ln.lnFullText }}
        </vs-checkbox>
      </div>
    </div>
    <div class="languages-action">
      <div class="languages-action-save">
        <vs-button @click="saveLanguages" class="mb-2">{{ $t('vue.save') }}</vs-button>
      </div>
      <div class="languages-action-cancel">
        <vs-button @click="cancel" type="border" class="mb-2">{{ $t('vue.cancel') }}</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TranslationsLanguage',
  data() {
    return {
      language: ''
    }
  },
  props: {
    showLanguageModal: {
      required: true
    }
  },
  watch: {
    showLanguageModal() {
      if (!this.showLanguageModal) {
        this.language = ''
      }
    }
  },
  computed: {
    filteredLanguages() {
      if (this.language && this.language.length === 0) {
        return this.languages
      }
      return this.languages.filter((x) => x.lnFullText.toLowerCase().indexOf(this.language.toLowerCase()) > -1)
    }
  },
  mounted() {
    this.language = ''
  },
  methods: {
    saveLanguages() {
      for (const language of this.languages.filter((x) => x.selected)) {
        const germanDefaultLanguage = this.default_de
        const isGerman = language.code === 'de'

        const found = this.translations.find((x) => x.ln === language.code)
        if (found) {
          const translation = JSON.parse(JSON.stringify(this.default_en))
          if (this.currentTranslationTab === 'button-and-hints' && !found.buttonAndHints) {
            found.buttonAndHints = {}
            if (!isGerman) {
              found.buttonAndHints.__isCopiedFromDefault = true
            }

            for (const property in translation.buttonAndHints) {
              if (!found.buttonAndHints[property] && !property.startsWith('__')) {
                found.buttonAndHints[property] = isGerman ? germanDefaultLanguage.buttonAndHints[property] : ''
              }
            }
          }
          if (this.currentTranslationTab === 'appointment-booking' && !found.appointmentBooking) {
            found.appointmentBooking = {}
            if (!isGerman) {
              found.appointmentBooking.__isCopiedFromDefault = true
            }
            for (const property in translation.appointmentBooking) {
              if (!found.appointmentBooking[property] && !property.startsWith('__')) {
                found.appointmentBooking[property] = isGerman ? germanDefaultLanguage.appointmentBooking[property] : ''
              }
            }
          }

          if (this.currentTranslationTab === 'email-translations' && !found.emailTranslations) {
            found.emailTranslations = {}
            if (!isGerman) {
              found.emailTranslations.__isCopiedFromDefault = true
            }
            for (const property in translation.emailTranslations) {
              if (!found.emailTranslations[property] && !property.startsWith('__')) {
                found.emailTranslations[property] = isGerman ? germanDefaultLanguage.emailTranslations[property] : ''
              }
            }
          }

          if (this.currentTranslationTab === 'web-connect' && !found.webConnect) {
            found.webConnect = {}
            for (const property in translation.webConnect) {
              if (!found.webConnect[property] && !property.startsWith('__')) {
                found.webConnect[property] = isGerman ? germanDefaultLanguage.webConnect[property] : ''
              }
            }
          }

          if (this.currentTranslationTab === 'gpt-connect' && !found.gptConnect) {
            found.gptConnect = {}
            for (const property in translation.gptConnect) {
              if (!found.gptConnect[property] && !property.startsWith('__')) {
                found.gptConnect[property] = isGerman ? germanDefaultLanguage.gptConnect[property] : ''
              }
            }
          }

          if (this.currentTranslationTab === 'qr-connect' && !found.qrConnect) {
            found.qrConnect = {}
            for (const property in translation.qrConnect) {
              if (!found.qrConnect[property] && !property.startsWith('__')) {
                found.qrConnect[property] = isGerman ? germanDefaultLanguage.qrConnect[property] : ''
              }
            }
          }

          found.ln = language.code
          found.lnText = language.lnText
          found.lnFullText = language.lnFullText
          found.canBeDeleted = true
          found.sort = this.translations.length

          this.updateTranslation({ translation: found, key: null, tab: this.currentTranslationTab })
        }

        if (!found) {
          const translation = JSON.parse(JSON.stringify(this.default_en))
          if (this.currentTranslationTab === 'button-and-hints') {
            if (!isGerman) {
              translation.buttonAndHints.__isCopiedFromDefault = true
            }
            delete translation.appointmentBooking
            delete translation.webConnect
            delete translation.gptConnect
            delete translation.qrConnect
            delete translation.emailTranslations
            for (const property in translation.buttonAndHints) {
              if (!property.startsWith('__')) {
                translation.buttonAndHints[property] = isGerman ? germanDefaultLanguage.buttonAndHints[property] : ''
              }
            }
          }
          if (this.currentTranslationTab === 'appointment-booking') {
            if (!isGerman) {
              translation.appointmentBooking.__isCopiedFromDefault = true
            }
            delete translation.buttonAndHints
            delete translation.webConnect
            delete translation.gptConnect
            delete translation.qrConnect
            delete translation.emailTranslations
            for (const property in translation.appointmentBooking) {
              if (!property.startsWith('__')) {
                translation.appointmentBooking[property] = isGerman ? germanDefaultLanguage.appointmentBooking[property] : ''
              }
            }
          }

          if (this.currentTranslationTab === 'email-translations') {
            if (!isGerman) {
              translation.emailTranslations.__isCopiedFromDefault = true
            }
            delete translation.buttonAndHints
            delete translation.webConnect
            delete translation.gptConnect
            delete translation.qrConnect
            delete translation.appointmentBooking
            for (const property in translation.emailTranslations) {
              if (!property.startsWith('__')) {
                translation.emailTranslations[property] = isGerman ? germanDefaultLanguage.emailTranslations[property] : ''
              }
            }
          }

          if (this.currentTranslationTab === 'web-connect') {
            delete translation.buttonAndHints
            delete translation.appointmentBooking
            delete translation.gptConnect
            delete translation.qrConnect
            delete translation.emailTranslations
            for (const property in translation.webConnect) {
              if (!property.startsWith('__')) {
                translation.webConnect[property] = isGerman ? germanDefaultLanguage.webConnect[property] : ''
              }
            }
          }

          if (this.currentTranslationTab === 'gpt-connect') {
            delete translation.buttonAndHints
            delete translation.appointmentBooking
            delete translation.webConnect
            delete translation.qrConnect
            delete translation.emailTranslations
            for (const property in translation.gptConnect) {
              if (!property.startsWith('__')) {
                translation.gptConnect[property] = isGerman ? germanDefaultLanguage.gptConnect[property] : ''
              }
            }
          }
          if (this.currentTranslationTab === 'qr-connect') {
            delete translation.buttonAndHints
            delete translation.appointmentBooking
            delete translation.webConnect
            delete translation.gptConnect
            delete translation.emailTranslations
            for (const property in translation.qrConnect) {
              if (!property.startsWith('__')) {
                translation.qrConnect[property] = isGerman ? germanDefaultLanguage.qrConnect[property] : ''
              }
            }
          }

          translation.ln = language.code
          translation.lnText = language.lnText
          translation.lnFullText = language.lnFullText
          translation.canBeDeleted = true
          translation.sort = this.translations.length
          this.addTranslation(translation)
        }

        language.selected = false
      }
      this.$emit('savedLanguages')
    },
    cancel() {
      this.$emit('closeLanguage')
    }
  }
}
</script>
<style lang="scss">
.checkbox-main {
  .vs-checkbox {
    width: 12px !important;
    height: 12px !important;
  }
  .con-slot-label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.177778px;
    color: #262629;
    margin-left: 23px;
  }
}
</style>
<style lang="scss" scoped>
.languages {
  width: calc(80vw);
  height: calc(80vh);

  border-radius: 6px;
  display: flex;
  flex-direction: column;

  &-search {
    background: #f4fbff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    height: 55px;
    min-height: 55px;
    align-items: center;
    justify-content: flex-start;

    div {
      height: 16px;
    }

    &-text {
      flex-grow: 0.96;
      input {
        border: none;
        background: none;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;

    > * {
      flex: 1 1 250px;
      padding: 15px;
      border-bottom: 0.25px solid #c4c4c4;
      max-width: 250px;
    }
  }

  &-action {
    min-height: 65px;
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    margin-left: 10px;

    div {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .languages {
    width: calc(95vw) !important;
    height: calc(95vh) !important;

    &-list {
      display: flex;
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      overflow-y: scroll;

      > * {
        width: 100%;
        flex: 1 1;
        border-bottom: 0.25px solid #c4c4c4;
        max-width: 100%;
      }
    }
  }
}
</style>
