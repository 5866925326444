<template>
  <svg :width="width" :height="height" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.74219V6.24208H5.49989" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M2.88246 9.99279C3.36874 11.373 4.29042 12.5579 5.50864 13.3687C6.72685 14.1796 8.17559 14.5726 9.63658 14.4885C11.0976 14.4045 12.4917 13.8478 13.6088 12.9025C14.7259 11.9573 15.5056 10.6745 15.8303 9.24758C16.155 7.82065 16.0072 6.32685 15.4091 4.99123C14.811 3.65562 13.7951 2.55056 12.5144 1.84256C11.2336 1.13455 9.75747 0.861954 8.30834 1.06584C6.8592 1.26972 5.51558 1.93905 4.47992 2.97296L1 6.24288"
      :stroke="stroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'ResetIcon',
  props: {
    color: {
      type: String,
      default: '#fff',
      required: false
    },
    stroke: {
      type: String,
      default: '#262629',
      required: false
    },
    width: {
      type: Number,
      default: 17,
      required: false
    },
    height: {
      type: Number,
      default: 16,
      required: false
    }
  }
}
</script>
