<template>
  <div>
    <connect-modal :show="showPopup" v-show="showPopup">
      <rich-text-input-popup :item="selectedPopupItem" @save-popup="onSavePopup" @cancel-popup="onCancelPopup"></rich-text-input-popup>
    </connect-modal>
    <div class="translations--body" :id="`body-box-${name}`" :key="`body-box-${name}-${translationUpdateKey}`">
      <div class="translations--body-box" v-for="(item, index) of translatedVersions" :key="`body-box-${name}_${index}_${translationUpdateKey}`">
        <div class="translations--body-box-item" :id="`body-box-${t.ln}-${type}-${translatedVersions[index].key}`">
          <div class="translations--body-box-item-text">
            <input
              type="text"
              :id="`input-${t.ln}-${type}-${translatedVersions[index].key}`"
              v-model="translatedVersions[index].data"
              :placeholder="placeHolderObject[translatedVersions[index].key]"
              :disabled="typeof translatedVersions[index].limit === 'object'"
              @change="onInputChanged(`${t.ln}-${type}-${translatedVersions[index].key}`)"
              :maxlength="translatedVersions[index].limit"
            />
          </div>
          <div v-if="typeof translatedVersions[index].limit === 'number'" class="translations--body-box-item-textcount" :key="updateKey">
            {{ translatedVersions[index].data.length }} / {{ translatedVersions[index].limit }}
          </div>
          <div v-else class="translations--body-box-item-icon" @click="onShowPopup(translatedVersions[index], t.ln)">
            <arrow-open-icon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArrowOpenIcon from '@/components/icons/ArrowOpenIcon.vue'
import ConnectModal from '@/components/ConnectModal.vue'
import RichTextInputPopup from './RichTextInputPopup.vue'
export default {
  name: 'TranslationBody',
  components: {
    ArrowOpenIcon,
    ConnectModal,
    RichTextInputPopup
  },
  props: {
    t: {
      required: true
    },
    webConnect: {
      required: false,
      default() {
        return {}
      }
    },
    gptConnect: {
      required: false,
      default() {
        return {}
      }
    },
    qrConnect: {
      required: false,
      default() {
        return {}
      }
    },
    name: {
      required: true
    },
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      updateKey: Math.random().toString(36).substring(2, 15),
      showPopup: false,
      selectedPopupItem: null
    }
  },
  computed: {
    placeHolderObject() {
      if (this.type === 'appointment-booking') {
        return { ...(this.default_en.appointmentBooking || {}) }
      }

      if (this.type === 'email-translations') {
        return { ...(this.default_en.emailTranslations || {}) }
      }

      if (this.type === 'button-and-hints') {
        return { ...(this.default_en.buttonAndHints || {}) }
      }

      if (this.type === 'web-connect') {
        return { ...(this.default_en.webConnect || {}) }
      }

      if (this.type === 'gpt-connect') {
        return { ...(this.default_en.gptConnect || {}) }
      }

      if (this.type === 'qr-connect') {
        return { ...(this.default_en.qrConnect || {}) }
      }

      return {}
    },

    translatedVersions() {
      const versions = []

      const limits = require('./textlimits.json')

      if (this.type === 'appointment-booking') {
        if (this.t.appointmentBooking) {
          const appointmentBooking = JSON.parse(JSON.stringify(this.t.appointmentBooking))
          for (const item in appointmentBooking) {
            if (typeof appointmentBooking[item] === 'string' || appointmentBooking[item] === null) {
              versions.push({ key: item, data: appointmentBooking[item] || '', limit: limits.appointmentBooking[item] || null })
            }
          }
        } else {
          const appointmentBooking = Object.assign({}, JSON.parse(JSON.stringify(this.default_en.appointmentBooking)))
          for (const item in appointmentBooking) {
            if (typeof appointmentBooking[item] === 'string' || appointmentBooking[item] === null) {
              versions.push({ key: item, data: appointmentBooking[item] || '', limit: limits.appointmentBooking[item] || null })
            }
          }
        }
      }

      if (this.type === 'email-translations') {
        if (this.t.emailTranslations) {
          const emailTranslations = JSON.parse(JSON.stringify(this.t.emailTranslations))
          for (const item in emailTranslations) {
            if (typeof emailTranslations[item] === 'string' || emailTranslations[item] === null) {
              versions.push({ key: item, data: emailTranslations[item] || '', limit: limits.emailTranslations[item] || null })
            }
          }
        } else {
          const emailTranslations = Object.assign({}, JSON.parse(JSON.stringify(this.default_en.emailTranslations)))
          for (const item in emailTranslations) {
            if (typeof emailTranslations[item] === 'string' || emailTranslations[item] === null) {
              versions.push({ key: item, data: emailTranslations[item] || '', limit: limits.emailTranslations[item] || null })
            }
          }
        }
      }

      if (this.type === 'button-and-hints') {
        if (this.t.buttonAndHints) {
          const buttonAndHints = JSON.parse(JSON.stringify(this.t.buttonAndHints))
          for (const item in buttonAndHints) {
            if (typeof buttonAndHints[item] === 'string' || buttonAndHints[item] === null) {
              versions.push({ key: item, data: buttonAndHints[item] || '', limit: limits.buttonAndHints[item] || null })
            }
          }
        } else {
          const buttonAndHints = Object.assign({}, JSON.parse(JSON.stringify(this.default_en.buttonAndHints)))
          for (const item in buttonAndHints) {
            if (typeof buttonAndHints[item] === 'string' || buttonAndHints[item] === null) {
              versions.push({ key: item, data: buttonAndHints[item] || '', limit: limits.appointmentBooking[item] || null })
            }
          }
        }
      }

      if (this.type === 'web-connect') {
        for (const item in this.webConnect) {
          if (typeof this.webConnect[item] === 'string' || this.webConnect[item] === null) {
            versions.push({ key: item, data: this.webConnect[item] || '', limit: limits.webConnect[item] || null })
          }
        }
      }

      if (this.type === 'gpt-connect') {
        for (const item in this.gptConnect) {
          if (typeof this.gptConnect[item] === 'string' || this.gptConnect[item] === null) {
            versions.push({ key: item, data: this.gptConnect[item] || '', limit: limits.gptConnect[item] || null })
          }
        }
      }

      if (this.type === 'qr-connect') {
        for (const item in this.qrConnect) {
          if (typeof this.qrConnect[item] === 'string' || this.qrConnect[item] === null) {
            versions.push({ key: item, data: this.qrConnect[item] || '', limit: limits.qrConnect[item] || null })
          }
        }
      }

      return versions.sort((a, b) => {
        const fa = a.key.toLowerCase(),
          fb = b.key.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    }
  },
  mounted() {
    const vm = this
    const currentDivName = `body-box-${this.name}`
    const div = document.getElementById(currentDivName)

    div.onscroll = function () {
      vm.updateScrollListener({ name: vm.name, scrollTop: this.scrollTop })
    }
  },
  watch: {
    'tscroll.scrollTop'() {
      if (this.tscroll && this.tscroll.name && this.tscroll.name !== this.name) {
        const currentBoxName = `body-box-${this.name}`
        const currentBox = document.getElementById(currentBoxName)
        if (currentBox) {
          currentBox.scrollTop = this.tscroll.scrollTop
        }
      }
    }
  },
  methods: {
    onShowPopup(item, ln) {
      this.selectedPopupItem = {
        translation: item,
        type: this.type,
        ln
      }
      this.showPopup = true
    },
    onSavePopup() {
      this.showPopup = false
      this.onInputChanged(`${this.selectedPopupItem.ln}-${this.selectedPopupItem.type}-${this.selectedPopupItem.translation.key}`)
      this.selectedPopupItem = null
    },
    onCancelPopup() {
      this.showPopup = false
      this.selectedPopupItem = null
    },

    onInput() {
      this.updateKey = Math.random().toString(36).substring(2, 15)
    },
    onInputChanged(key) {
      const t = this.translations.find((x) => x.lnText === this.t.lnText)

      if (this.type === 'appointment-booking') {
        const appointmentBooking = {}
        for (const version of this.translatedVersions) {
          appointmentBooking[version.key] = JSON.parse(JSON.stringify(version.data))
        }
        t.appointmentBooking = Object.keys(appointmentBooking).length > 0 ? appointmentBooking : null
        t.appointmentBooking.__isCopiedFromDefault = false

        this.updateTranslation({ translation: t, key, tab: 'appointment-booking' })
      }

      if (this.type === 'email-translations') {
        const emailTranslations = {}
        for (const version of this.translatedVersions) {
          emailTranslations[version.key] = JSON.parse(JSON.stringify(version.data))
        }
        t.emailTranslations = Object.keys(emailTranslations).length > 0 ? emailTranslations : null
        t.emailTranslations.__isCopiedFromDefault = false

        this.updateTranslation({ translation: t, key, tab: 'email-translations' })
      }

      if (this.type === 'button-and-hints') {
        const buttonAndHints = {}
        for (const version of this.translatedVersions) {
          buttonAndHints[version.key] = JSON.parse(JSON.stringify(version.data))
        }
        t.buttonAndHints = Object.keys(buttonAndHints).length > 0 ? buttonAndHints : null
        t.buttonAndHints.__isCopiedFromDefault = false

        this.updateTranslation({ translation: t, key, tab: 'button-and-hints' })
      }

      if (this.type === 'web-connect') {
        const webConnect = {}
        for (const version of this.translatedVersions) {
          webConnect[version.key] = JSON.parse(JSON.stringify(version.data))
        }
        t.webConnect = Object.keys(webConnect).length > 0 ? webConnect : null
        this.updateTranslation({ translation: t, key, tab: 'web-connect' })
      }

      if (this.type === 'gpt-connect') {
        const gptConnect = {}
        for (const version of this.translatedVersions) {
          gptConnect[version.key] = JSON.parse(JSON.stringify(version.data))
        }
        t.gptConnect = Object.keys(gptConnect).length > 0 ? gptConnect : null
        this.updateTranslation({ translation: t, key, tab: 'gpt-connect' })
      }

      if (this.type === 'qr-connect') {
        const qrConnect = {}
        for (const version of this.translatedVersions) {
          qrConnect[version.key] = JSON.parse(JSON.stringify(version.data))
        }
        t.qrConnect = Object.keys(qrConnect).length > 0 ? qrConnect : null
        this.updateTranslation({ translation: t, key, tab: 'qr-connect' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flash-t {
  animation: flash-t 0.4s;
}

.flash-t div {
  color: #fff !important;
}
.flash-t input {
  color: #fff !important;
}

@keyframes flash-t {
  50% {
    background: #275D73;
    color: #fff;
  }
  100% {
    background: transparent;
    color: #fff;
  }
}

.translations {
  &--body {
    display: flex;
    flex-direction: column;
    height: 427px;
    overflow-y: scroll;

    &-box {
      border-bottom: 0.25px solid #c4c4c4;
      height: 40px;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-item {
        display: flex;
        justify-content: space-between;
        width: 236px;
        height: 30px;
        border: 0.25px solid #c4c4c4;
        border-radius: 3px;

        &-text {
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          input {
            border: none;
            width: 180px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.177778px;
            background-color: transparent;

            color: #262629;
          }

          input:focus::placeholder {
            color: transparent;
          }
          input:focus::-webkit-input-placeholder {
            color: transparent;
          }
          input:focus:-moz-placeholder {
            color: transparent;
          } /* FF 4-18 */
          input:focus::-moz-placeholder {
            color: transparent;
          } /* FF 19+ */
          input:focus:-ms-input-placeholder {
            color: transparent;
          } /* IE 10+ */

          ::placeholder {
            color: #b2b2b2;
          }
          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #b2b2b2;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #b2b2b2;
          }

          input:disabled::placeholder {
            color: #b2b2b2;
            opacity: 1;
            -webkit-text-fill-color: #b2b2b2; /* Override iOS / Android font color change */
            -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
          }
          input:disabled::-webkit-input-placeholder {
            color: #b2b2b2;
            opacity: 1;
            -webkit-text-fill-color: #b2b2b2; /* Override iOS / Android font color change */
            -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
          }
          input:disabled:-moz-placeholder {
            color: #b2b2b2;
            opacity: 1;
            -webkit-text-fill-color: #b2b2b2; /* Override iOS / Android font color change */
            -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
          } /* FF 4-18 */
          input:disabled::-moz-placeholder {
            color: #b2b2b2;
            opacity: 1;
            -webkit-text-fill-color: #b2b2b2; /* Override iOS / Android font color change */
            -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
          } /* FF 19+ */
          input:disabled:-ms-input-placeholder {
            color: #b2b2b2;
            opacity: 1;
            -webkit-text-fill-color: #b2b2b2; /* Override iOS / Android font color change */
            -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
          } /* IE 10+ */

          input:disabled {
            background: none;
            color: #b2b2b2;
            opacity: 1;
            -webkit-text-fill-color: #b2b2b2; /* Override iOS / Android font color change */
            -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
          }
        }

        &-textcount,
        &-icon {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 8px;
          line-height: 15px;
          letter-spacing: -0.177778px;
          color: #b2b2b2;
        }

        &-icon {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
