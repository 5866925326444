<template>
  <translations type="appointment-booking" :key="translationUpdateKey" />
</template>
<script>
import Translations from './Translations.vue'
export default {
  name: 'TranslationsAppointmentBooking',
  components: {
    Translations
  }
}
</script>
<style lang="scss" scoped></style>
