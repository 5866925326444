<template>
  <div class="translation-ws">
    <select name="widgets" v-model="widgetId">
      <option disabled value="-1" selected>{{ $t('vue.chooseAWidget') }}</option>
      <option v-for="option in dialogList" :value="option.id" :key="option.id">{{ option.name }}</option>
    </select>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TranslationWidgetSelection',
  data() {
    return {
      widgetId: -1
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      dialogs: 'dialogs'
    }),
    dialogList() {
      return this.dialogs.map((x) => {
        return {
          id: x.id,
          name: x.name,
          isGptWidgetEnabled: x.isGptWidgetEnabled
        }
      }).filter((item) => item.isGptWidgetEnabled)
    }
  },
  watch: {
    widgetId() {
      if (this.widgetId) {
        this.updateSelectedGptWidget(this.widgetId)
        this.getSavedGptConnectTranslation()
        this.updateShowGptWidgetSelection(false)
      } else {
        this.updateSelectedGptWidget(null)
        this.updateShowGptWidgetSelection(true)
      }
    }
  },
  mounted() {
    for (const translation of this.translations) {
      translation.gptConnect = null
    }
  }
}
</script>

<style lang="scss" scoped>
.translation-ws {
  padding: 10px;
  select {
    padding: 6px;
    border-radius: 6px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    option {
      background-color: #275D73;
      color: #fff;
    }
    option:disabled {
      background-color: #c7c7c7;
      color: #fff;
    }
  }
}
</style>
