<template>
  <div v-if="item" class="rte-translation">
    <div class="translation-rte">
      <div class="translation-rte-container">
        <div class="translation-rte-container-item">
          <div class="translation-rte-container-item--header">
            <div class="translation-rte-container-item--header-large">{{ $t('vue.source') }}</div>
            <div class="translation-rte-container-item--header-smail">English</div>
          </div>
          <div id="translation-rte-container-item--box--source" class="translation-rte-container-item--box">
            <div class="translation-rte-container-item--rte" v-for="(data, key) in sortedPropertySource" :key="key">
              <h4>{{ $t(`translations.${key}`) }}</h4>
              <vs-textarea
                v-if="key !== 'name' && item.translation.limit[key] && item.translation.limit[key].type === 'editor'"
                disabled
                v-model="tsource[key]"
                height="150px"
              />

              <vs-input class="w-full" v-else height="100px" disabled v-model="tsource[key]" />
            </div>
          </div>
        </div>
        <div class="translation-rte-container-item">
          <div class="translation-rte-container-item--header">
            <div class="translation-rte-container-item--header-large">{{ $t('vue.translation') }}</div>
            <div class="translation-rte-container-item--header-smail">{{ selectedLanguage.lnFullText }}</div>
          </div>
          <div id="translation-rte-container-item--box--destination" class="translation-rte-container-item--box">
            <div class="translation-rte-container-item--rte" v-for="(t2data, key) in sortedPropertyDestination" :key="key">
              <h4>{{ $t(`translations.${key}`) }}</h4>
              <quill-editor
                v-if="key !== 'name' && item.translation.limit[key] && item.translation.limit[key].type === 'editor'"
                v-model="item.translation.data[key]"
                ref="quillEditorSource"
                :options="editorOption"
              >
              </quill-editor>
              <vs-input v-else class="w-full" :counter="item.translation.limit[key]" height="100px" v-model="item.translation.data[key]" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="translation-rte-action">
      <div class="translation-rte-action-btn translation-rte-action-btn--save" @click="onSave">
        {{ $t('vue.save') }}
      </div>
      <div class="translation-rte-action-btn translation-rte-action-btn--cancel" @click="onCancel">
        {{ $t('vue.cancel') }}
      </div>
    </div>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'ObjectInputPopup',
  components: {
    quillEditor
  },
  data() {
    return {
      tsource: {},
      tdata: {},
      editorOption: {
        modules: {
          toolbar: {
            container: [['bold', 'italic', 'underline', 'link', { size: ['small', false, 'large', 'huge'] }]]
          }
        }
      },
      sortOrder: {
        name: 1,
        subject: 2,
        title: 3,
        body: 4
      }
    }
  },
  props: ['item'],

  mounted() {
    const vm = this

    const currentDivSourceName = 'translation-rte-container-item--box--source'
    const divSource = document.getElementById(currentDivSourceName)
    divSource.onscroll = function () {
      vm.updateScrollListener({ name: 'translation-rte-container-item--box--source', scrollTop: this.scrollTop })
    }

    const currentDivDestName = 'translation-rte-container-item--box--destination'
    const divDestination = document.getElementById(currentDivDestName)
    divDestination.onscroll = function () {
      vm.updateScrollListener({ name: 'translation-rte-container-item--box--destination', scrollTop: this.scrollTop })
    }

    const translation = this.item.translation
    this.tdata = translation.data

    if (translation.key) {
      if (this.item.type === 'email-translations') {
        this.tsource = this.default_en.emailTranslations[translation.key]
      }
    }
  },

  computed: {
    allLanguages() {
      const langs = this.languages.filter((x) => {
        return {
          code: x.code,
          lnFullText: x.lnFullText
        }
      })
      langs.push({ code: 'en', lnFullText: 'English' })
      langs.push({ code: 'de', lnFullText: 'German' })
      return langs
    },
    selectedLanguage() {
      const language = this.allLanguages.find((x) => x.code === this.item.ln)
      return language || { code: '', lnFullText: '' }
    },
    sortedPropertySource() {
      const sortedPropertyNames = Object.keys(this.tsource).sort((a, b) => {
        return this.sortOrder[a] - this.sortOrder[b]
      })
      const sortedObj = {}

      for (const propertyName of sortedPropertyNames) {
        sortedObj[propertyName] = this.tsource[propertyName]
      }
      return sortedObj
    },
    sortedPropertyDestination() {
      const sortedPropertyNames = Object.keys(this.tdata).sort((a, b) => {
        return this.sortOrder[a] - this.sortOrder[b]
      })
      const sortedObj = {}
      for (const propertyName of sortedPropertyNames) {
        sortedObj[propertyName] = this.tdata[propertyName]
      }
      return sortedObj
    }
  },

  methods: {
    onSave() {
      if (this.item.translation.limit.type === 'text-area' && this.item.translation.data.length > this.item.translation.limit.limit) {
        return
      }
      this.$emit('save-popup')
    },
    onCancel() {
      const translation = this.item.translation
      translation.data = this.tdata
      this.$emit('cancel-popup')
    }
  },
  watch: {
    'item.translation.key'() {
      const translation = this.item.translation
      this.tdata = translation.data

      if (translation.key) {
        if (this.item.type === 'email-translations') {
          this.tsource = this.default_en.emailTranslations[translation.key]
        }
      }
    },
    'tscroll.scrollTop'() {
      if (this.tscroll && this.tscroll.name) {
        const currentBoxName =
          this.tscroll.name === 'translation-rte-container-item--box--source'
            ? 'translation-rte-container-item--box--destination'
            : 'translation-rte-container-item--box--source'
        const currentBox = document.getElementById(currentBoxName)
        if (currentBox) {
          currentBox.scrollTop = this.tscroll.scrollTop
        }
      }
    }
  }
}
</script>
<style lang="scss">
.translations .con-ul-tabs {
  z-index: 1;
}
.translations .modal-container {
  @media only screen and (max-width: 1200px) {
    margin-left: 0px !important;
  }
  @media only screen and (min-width: 1201px) {
    margin-left: 325px !important;
  }
}
.translation-rte-container-item--rte .quill-editor .ql-disabled {
  background-color: #ccc;
}
</style>
<style lang="scss" scoped>
.rte-translation {
  @media only screen and (min-width: 1601px) {
    width: 1200px;
    height: 400px;
  }

  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 1000px;
    height: 400px;
  }

  @media only screen and (min-width: 1301px) and (max-width: 1400px) {
    width: 900px;
    height: 400px;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1300px) {
    width: 800px;
    height: 400px;
  }

  @media only screen and (max-width: 1200px) {
    width: calc(98vw);
    height: calc(98vh);
  }

  .translation-rte {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 1201px) {
      padding: 30px;
    }

    @media only screen and (max-width: 1200px) {
      padding: 15px;
    }

    &-action {
      border: none;
      border-top: 0.25px solid #c4c4c4;

      flex-grow: 1;
      padding: 30px;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      > div {
        margin: 0px 6px;
      }

      :hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        font-size: 14px;
      }

      &-btn {
        width: 97.28px;
        height: 34px;
        border-radius: 6px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        &--save {
          background: #01aaf4;
          color: #fff;
        }

        &--cancel {
          color: #01aaf4;
        }
      }
    }

    &-container {
      display: flex;
      @media only screen and (min-width: 1201px) {
        flex-direction: row;
        justify-content: space-between;
      }
      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: calc(82vh);
      }

      &-item {
        background: #ffffff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

        &--box {
          overflow: scroll;
        }

        @media only screen and (min-width: 1601px) {
          width: 560px;
          height: 246px;
        }

        @media only screen and (min-width: 1401px) and (max-width: 1600px) {
          width: 460px;
          height: 246px;
        }

        @media only screen and (min-width: 1301px) and (max-width: 1400px) {
          width: 410px;
          height: 246px;
        }

        @media only screen and (min-width: 1201px) and (max-width: 1300px) {
          width: 360px;
          height: 246px;
        }

        @media only screen and (max-width: 1200px) {
          width: 100%;
          height: calc(40vh);
        }

        display: flex;
        flex-direction: column;

        &--header {
          background: #f4fbff;
          height: 70px;
          padding: 20px 20px 10px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &-large {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 15px;
            letter-spacing: -0.177778px;
            color: #262629;
            text-transform: uppercase;
          }
          &-small {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.177778px;
            color: #b2b2b2;
          }
        }

        &--rte {
          background: #fff;
          padding: 13px 18px 13px 11px;
          flex-grow: 1;

          .quill-editor {
            @media only screen and (min-width: 1201px) {
              height: 108px;
              max-height: 108px;
              margin-bottom: 50px;
            }

            @media only screen and (max-width: 1200px) {
              height: calc(20vh);
              max-height: calc(20vh);
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
