var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showGptWidgetSelection
        ? _c("div", [_c("gpt-widget-selection")], 1)
        : _c("translations", {
            key: _vm.translationUpdateKey,
            attrs: { type: "gpt-connect" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }