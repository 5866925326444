<template>
  <svg :width="width" :height="height" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 8.08333L8.08333 1" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1 1H8.08333V8.08333" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'ArrowOpenIcon',
  props: {
    stroke: {
      type: String,
      default: '#01AAF4'
    },
    width: {
      type: Number,
      default: 9
    },
    height: {
      type: Number,
      default: 9
    }
  }
}
</script>
