<template>
  <div class="translations">
    <div class="vx-row translations-button-add">
      <div class="vx-col w-full mb-8">
        <vs-button icon-pack="feather" icon="icon-plus" type="filled" @click="onAddLanguage">
          {{ $t('vue.addLanguage') }}
        </vs-button>
      </div>
    </div>

    <vs-popup :title="$t('vue.deleteTranslation')" :active.sync="showDeletePopup">
      <p class="mb-2">
        {{ $t('vue.confirmQuestion') }}
      </p>
      <vs-button @click="onConfirmDeleteTranslation()" color="#e8546f" type="filled" class="mr-2">
        {{ $t('vue.confirm') }}
      </vs-button>
      <vs-button @click="onCancelDeleteTranslation()" type="border" color="#e8546f">{{ $t('vue.cancel') }}</vs-button>
    </vs-popup>

    <vs-popup :title="$t('vue.resetTranslation')" :active.sync="showResetPopup">
      <p class="mb-2">
        {{ $t('vue.confirmQuestion') }}
      </p>
      <vs-button @click="onConfirmResetTranslation()" color="#e8546f" type="filled" class="mr-2">
        {{ $t('vue.confirm') }}
      </vs-button>
      <vs-button @click="onCancelResetTranslation()" type="border" color="#e8546f">{{ $t('vue.cancel') }}</vs-button>
    </vs-popup>

    <connect-modal :show="showLanguageModal" bkcolor="#F7F7F7">
      <div class="ui-cancelled-sub-mollie-close-button" style="position: absolute; top: 10px; right: 10px" @click="onCloseLanguageModal">
        <close-circle-icon class="ui-cancelled-sub-mollie-close-button-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <languages :showLanguageModal="showLanguageModal" @savedLanguages="onCloseLanguageModal" @closeLanguage="onCloseLanguageModal" />
    </connect-modal>

    <div>
      <vs-tabs>
        <vs-tab :label="$t('vue.buttonAndHints')" @click="onShowButtonAndHints">
          <div class="tab-text">
            <button-and-hints />
          </div>
        </vs-tab>
        <vs-tab :label="$t('features.appointmentBooking')" @click="onShowAppointmentBooking">
          <div class="tab-text">
            <appointment-booking />
          </div>
        </vs-tab>
        <vs-tab :label="$t('routes.dialog')" @click="onShowWebConnect">
          <div class="tab-text">
            <web-connect />
          </div>
        </vs-tab>
        <vs-tab :label="$t('routes.gptConnect')" @click="onShowGptConnect">
          <div class="tab-text">
            <gpt-connect />
          </div>
        </vs-tab>
        <vs-tab :label="$t('routes.campaigns')" @click="onShowQRConnect">
          <div class="tab-text">
            <qr-connect />
          </div>
        </vs-tab>
        <vs-tab :label="$t('vue.email')" @click="onShowEmailTranslations">
          <div class="tab-text">
            <email-translations />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import ButtonAndHints from './components/ButtonAndHints'
import EmailTranslations from './components/EmailTranslations'
import AppointmentBooking from './components/AppointmentBooking'
import WebConnect from './components/WebConnect'
import GptConnect from './components/GptConnect'
import QrConnect from './components/QrConnect'
import Languages from './components/Languages'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'
import ConnectModal from '@/components/ConnectModal.vue'

import { mapGetters, mapMutations } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

export default {
  name: 'Translations',
  components: {
    ButtonAndHints,
    EmailTranslations,
    AppointmentBooking,
    WebConnect,
    GptConnect,
    QrConnect,
    Languages,
    CloseCircleIcon,
    ConnectModal
  },
  data() {
    return {
      showLanguageModal: false,
      activeTabIndex: 0,
      webconnectMapping: require('./components/webconnect-field-mapping.json'),
      gptconnectMapping: require('./components/gptconnect-field-mapping.json'),
      qrconnectMapping: require('./components/qrconnect-field-mapping.json'),
      showDeletePopup: false,
      showResetPopup: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      addKey: 'languages/addKey',
      updateKey: 'languages/updateKey',
      removedLanguage: 'languages/removedLanguage',
      updatedField: 'languages/updatedField',
      showDeleteTranslationModal: 'languages/showDeleteTranslationModal',
      showResetTranslationModal: 'languages/showResetTranslationModal',
      translationToBeDeleted: 'languages/translationToBeDeleted',
      translationToBeReseted: 'languages/translationToBeReseted'
    })
  },

  methods: {
    ...mapMutations({
      updateCurrentTab: 'languages/UPDATE_CURRENT_TAB',
      updateTranslatonUpdateKey: 'languages/UPDATE_TRANSLATIONUPDATEKEY',
      updateTranslationToBeDeleted: 'languages/UPDATE_TRANSLATION_TO_BE_DELETED',
      updateTranslationToBeReseted: 'languages/UPDATE_TRANSLATION_TO_BE_RESETED',
      updateShowDeleteTranslationModal: 'languages/UPDATE_SHOW_DELETE_TRANSLATION_MODAL',
      updateShowResetTranslationModal: 'languages/UPDATE_SHOW_RESET_TRANSLATION_MODAL',
      updateTranslationUpdateKey: 'languages/UPDATE_TRANSLATIONUPDATEKEY',
      resetTranslation: 'languages/RESET_TRANSLATION'
    }),
    onCancelDeleteTranslation() {
      this.updateTranslationToBeDeleted(null)
      this.updateShowDeleteTranslationModal(false)
    },
    onConfirmDeleteTranslation() {
      this.onDeleteTranslation(this.translationToBeDeleted)
      this.updateShowDeleteTranslationModal(false)
    },
    async onConfirmResetTranslation() {
      if (this.translationToBeReseted.ln === 'en') {
        await this.onDeleteTranslation(this.translationToBeReseted)
      } else if (this.translationToBeReseted.ln === 'de') {
        this.onResetTranslation(this.translationToBeReseted)
      } else {
        await this.onRevertTranslation(this.translationToBeReseted)
      }

      this.updateTranslationToBeReseted(null)
      this.updateShowResetTranslationModal(false)
    },
    onCancelResetTranslation() {
      this.updateTranslationToBeReseted(null)
      this.updateShowResetTranslationModal(false)
    },

    onResetTranslation(t) {
      this.resetTranslation(t.ln)
    },

    async onRevertTranslation(t) {
      this.removeTranslation(t.ln)
      if (this.currentTranslationTab === 'web-connect') {
        const dialogRef = await this.$db.collection('dialogs').doc(this.selectedWidget).get()
        const dialog = dialogRef.data()
        const translation = this.translations.find((x) => x.ln === t.ln)

        if (t.ln === 'en' && dialog && translation && translation.webConnect) {
          dialog.sloganTitle = this.$i18n.t('vue.dialogTitleDefault', 'en')
          dialog.text = this.$i18n.t('vue.popupTextDefault', 'en')
          dialog.cancelButtonText = this.$i18n.t('vue.cancelButtonTextDefault', 'en')
          dialog.confirmButtonText = this.$i18n.t('vue.confirmButtonTextDefault', 'en')
          dialog.textStep2 = this.$i18n.t('vue.textStep2DefaultText', 'en')
          dialog.waitingRoomMessage = this.$i18n.t('vue.waitingRoomDefaultMessage', 'en')

          dialog.moreInformationButtonText = this.$i18n.t('vue.moreInformation', 'en')

          if (dialog.sessionEndScreen) {
            dialog.sessionEndScreen.titleClosingMessage = this.$i18n.t('vue.sessionEndScreenDefaultTitle', 'en')
            dialog.sessionEndScreen.copyClosingMessage = this.$i18n.t('vue.sessionEndScreenDefaultCopy', 'en')
          }

          if (dialog.contactForm) {
            dialog.contactForm.message = this.$i18n.t('vue.contactFormText', 'en')
            dialog.contactForm.sendMessageTitle = this.$i18n.t('vue.sendMessageDefaultTitle', 'en')
            dialog.contactForm.sendMessageCopy = this.$i18n.t('vue.sendMessageDefaultCopy', 'en')
            dialog.contactForm.bookingSlotTitle = this.$i18n.t('vue.bookingSlotDefaultTitle', 'en')
            dialog.contactForm.bookingSlotCopy = this.$i18n.t('vue.bookingSlotDefaultCopy', 'en')
          }

          if (translation.feedbackFormQuestionFirst) {
            dialog.feedbackFormQuestionFirst = translation.feedbackFormQuestionFirst
          }
          if (translation.feedbackFormQuestionSecond) {
            dialog.feedbackFormQuestionSecond = translation.feedbackFormQuestionSecond
          }
          if (translation.feedbackFormQuestionThird) {
            dialog.feedbackFormQuestionThird = translation.feedbackFormQuestionThird
          }

          if (dialog.vega) {
            dialog.vega.text = this.$i18n.t('webConnect.vegaText', 'en')
            dialog.vega.message = this.$i18n.t('webConnect.vegaMessage', 'en')
          }

          dialog.automatedChatResponse = this.$i18n.t('webConnect.automatedChatReponse', 'en')

          await this.$db.collection('dialogs').doc(this.selectedWidget).set(dialog, { merge: true })

          const _webConnect = { ...translation.webConnect }
          _webConnect.bookingSlotDefaultCopy = this.$i18n.t('vue.bookingSlotDefaultCopy', 'en')
          _webConnect.bookingSlotDefaultTitle = this.$i18n.t('vue.bookingSlotDefaultTitle', 'en')
          _webConnect.cancelButtonTextDefault = this.$i18n.t('vue.cancelButtonTextDefault', 'en')
          _webConnect.confirmButtonTextDefault = this.$i18n.t('vue.confirmButtonTextDefault', 'en')
          _webConnect.contactFormText = this.$i18n.t('vue.contactFormText', 'en')
          _webConnect.dialogTitleDefault = this.$i18n.t('vue.dialogTitleDefault', 'en')
          _webConnect.moreInformation = this.$i18n.t('vue.moreInformation', 'en')
          _webConnect.popupTextDefault = this.$i18n.t('vue.popupTextDefault', 'en')
          _webConnect.sendMessageDefaultCopy = this.$i18n.t('vue.sendMessageDefaultCopy', 'en')
          _webConnect.sendMessageDefaultTitle = this.$i18n.t('vue.sendMessageDefaultTitle', 'en')
          _webConnect.sessionEndScreenDefaultCopy = this.$i18n.t('vue.sessionEndScreenDefaultCopy', 'en')
          _webConnect.sessionEndScreenDefaultTitle = this.$i18n.t('vue.sessionEndScreenDefaultTitle', 'en')
          _webConnect.textStep2DefaultText = this.$i18n.t('vue.textStep2DefaultText', 'en')
          _webConnect.waitingRoomDefaultMessage = this.$i18n.t('vue.waitingRoomDefaultMessage', 'en')
          _webConnect.privacyInformationHtml = this.$i18n.t('vue.privacyInformationText', 'en')
          _webConnect.vegaText = this.$i18n.t('webConnect.vegaText', 'en')
          _webConnect.vegaMessage = this.$i18n.t('webConnect.vegaMessage', 'en')
          _webConnect.automatedChatResponse = this.$i18n.t('webConnect.automatedChatReponse', 'en')

          const obj = {
            webConnect: _webConnect,
            widgetId: this.selectedWidget
          }
          this.updateSelectedWidgetSavedTranslation(obj)

          this.updateTranslationUpdateKey()
        } else {
          await this.$db
            .collection('translations')
            .doc(this.activeUserInfo.company)
            .collection('web-connect')
            .doc(this.selectedWidget)
            .collection('languages')
            .doc(t.ln)
            .delete()
        }
      }
      if (this.currentTranslationTab === 'qr-connect') {
        const dialogRef = await this.$db.collection('campaigns').doc(this.selectedQRWidget).get()
        const dialog = dialogRef.data()
        const translation = this.translations.find((x) => x.ln === t.ln)

        if (t.ln === 'en' && dialog && translation && translation.qrConnect) {
          translation.qrConnect.feedbackFormQuestionFirst = dialog.feedbackFormQuestionFirst || ''
          translation.qrConnect.feedbackFormQuestionSecond = dialog.feedbackFormQuestionSecond || ''
          translation.qrConnect.feedbackFormQuestionThird = dialog.feedbackFormQuestionThird || ''

          dialog.moreInformationButtonText = this.$i18n.t('vue.moreInformation', 'en')

          dialog.title = this.$i18n.t('vue.dialogTitleDefault', 'en')
          dialog.text = this.$i18n.t('vue.popupTextDefault', 'en')
          dialog.cancelButtonText = this.$i18n.t('vue.cancelButtonTextDefault', 'en')
          dialog.confirmButtonText = this.$i18n.t('vue.confirmButtonTextDefault', 'en')
          dialog.textStep2 = this.$i18n.t('vue.textStep2DefaultText', 'en')
          dialog.waitingRoomMessage = this.$i18n.t('vue.waitingRoomDefaultMessage', 'en')

          if (dialog.sessionEndScreen) {
            dialog.sessionEndScreen.titleClosingMessage = this.$i18n.t('vue.sessionEndScreenDefaultTitle', 'en')
            dialog.sessionEndScreen.copyClosingMessage = this.$i18n.t('vue.sessionEndScreenDefaultCopy', 'en')
          }

          if (dialog.contactForm) {
            dialog.contactForm.message = this.$i18n.t('vue.contactFormText', 'en')
            dialog.contactForm.sendMessageTitle = this.$i18n.t('vue.sendMessageDefaultTitle', 'en')
            dialog.contactForm.sendMessageCopy = this.$i18n.t('vue.sendMessageDefaultCopy', 'en')
            dialog.contactForm.bookingSlotTitle = this.$i18n.t('vue.bookingSlotDefaultTitle', 'en')
            dialog.contactForm.bookingSlotCopy = this.$i18n.t('vue.bookingSlotDefaultCopy', 'en')
          }

          if (translation.feedbackFormQuestionFirst) {
            dialog.feedbackFormQuestionFirst = translation.feedbackFormQuestionFirst
          }
          if (translation.feedbackFormQuestionSecond) {
            dialog.feedbackFormQuestionSecond = translation.feedbackFormQuestionSecond
          }
          if (translation.feedbackFormQuestionThird) {
            dialog.feedbackFormQuestionThird = translation.feedbackFormQuestionThird
          }

          await this.$db.collection('campaigns').doc(this.selectedQRWidget).set(dialog, { merge: true })

          const _qrConnect = { ...translation.qrConnect }
          _qrConnect.bookingSlotDefaultCopy = this.$i18n.t('vue.bookingSlotDefaultCopy', 'en')
          _qrConnect.bookingSlotDefaultTitle = this.$i18n.t('vue.bookingSlotDefaultTitle', 'en')
          _qrConnect.cancelButtonTextDefault = this.$i18n.t('vue.cancelButtonTextDefault', 'en')
          _qrConnect.confirmButtonTextDefault = this.$i18n.t('vue.confirmButtonTextDefault', 'en')
          _qrConnect.contactFormText = this.$i18n.t('vue.contactFormText', 'en')
          _qrConnect.dialogTitleDefault = this.$i18n.t('vue.dialogTitleDefault', 'en')
          _qrConnect.popupTextDefault = this.$i18n.t('vue.popupTextDefault', 'en')
          _qrConnect.moreInformation = this.$i18n.t('vue.moreInformation', 'en')
          _qrConnect.sendMessageDefaultCopy = this.$i18n.t('vue.sendMessageDefaultCopy', 'en')
          _qrConnect.sendMessageDefaultTitle = this.$i18n.t('vue.sendMessageDefaultTitle', 'en')
          _qrConnect.sessionEndScreenDefaultCopy = this.$i18n.t('vue.sessionEndScreenDefaultCopy', 'en')
          _qrConnect.sessionEndScreenDefaultTitle = this.$i18n.t('vue.sessionEndScreenDefaultTitle', 'en')
          _qrConnect.textStep2DefaultText = this.$i18n.t('vue.textStep2DefaultText', 'en')
          _qrConnect.waitingRoomDefaultMessage = this.$i18n.t('vue.waitingRoomDefaultMessage', 'en')
          _qrConnect.privacyInformationHtml = this.$i18n.t('vue.privacyInformationText', 'en')

          const obj = {
            qrConnect: _qrConnect,
            widgetId: this.selectedQRWidget
          }
          this.updateSelectedQRWidgetSavedTranslation(obj)

          this.updateTranslationUpdateKey()
        } else {
          await this.$db
            .collection('translations')
            .doc(this.activeUserInfo.company)
            .collection('qr-connect')
            .doc(this.selectedQRWidget)
            .collection('languages')
            .doc(t.ln)
            .delete()
        }
      }
      if (this.currentTranslationTab === 'gpt-connect') {
        const dialogRef = await this.$db.collection('dialogs').doc(this.selectedGptWidget).get()
        const dialog = dialogRef.data()
        const translation = this.translations.find((x) => x.ln === t.ln)

        if (t.ln === 'en' && dialog && translation && translation.gptConnect) {
          if (dialog.gpt) {
            dialog.gpt.speechBubbleText = this.$i18n.t('vue.gptSpeechBubbleText', 'en')
            dialog.gpt.botWelcomeMessage = this.$i18n.t('vue.gptBotWelcomeMessage', 'en')
          }

          await this.$db.collection('dialogs').doc(this.selectedGptWidget).set(dialog, { merge: true })

          const _gptConnect = { ...translation.gptConnect }
          _gptConnect.speechBubbleText = this.$i18n.t('vue.gptSpeechBubbleText', 'en')
          _gptConnect.botWelcomeMessage = this.$i18n.t('vue.gptBotWelcomeMessage', 'en')

          const obj = {
            gptConnect: _gptConnect,
            widgetId: this.selectedGptWidget
          }
          this.updateSelectedGptWidgetSavedTranslation(obj)

          this.updateTranslationUpdateKey()
        } else {
          await this.$db
            .collection('translations')
            .doc(this.activeUserInfo.company)
            .collection('gpt-connect')
            .doc(this.selectedGptWidget)
            .collection('languages')
            .doc(t.ln)
            .delete()
        }
      }
    },

    async onDeleteTranslation(t) {
      this.removeTranslation(t.ln)

      if (this.currentTranslationTab === 'web-connect') {
        await this.$db
          .collection('translations')
          .doc(this.activeUserInfo.company)
          .collection('web-connect')
          .doc(this.selectedWidget)
          .collection('languages')
          .doc(t.ln)
          .delete()
      }
      if (this.currentTranslationTab === 'qr-connect') {
        await this.$db
          .collection('translations')
          .doc(this.activeUserInfo.company)
          .collection('qr-connect')
          .doc(this.selectedQRWidget)
          .collection('languages')
          .doc(t.ln)
          .delete()
      }
      if (this.currentTranslationTab === 'gpt-connect') {
        await this.$db
          .collection('translations')
          .doc(this.activeUserInfo.company)
          .collection('gpt-connect')
          .doc(this.selectedGptWidget)
          .collection('languages')
          .doc(t.ln)
          .delete()
      }
    },
    onShowButtonAndHints() {
      this.updateCurrentTab('button-and-hints')
    },
    onShowEmailTranslations() {
      this.updateCurrentTab('email-translations')
    },
    onShowAppointmentBooking() {
      this.updateCurrentTab('appointment-booking')
    },
    onShowWebConnect() {
      this.updateCurrentTab('web-connect')
      this.updateShowWidgetSelection(true)
      this.updateSelectedWidget(null)
    },
    onShowQRConnect() {
      this.updateCurrentTab('qr-connect')
      this.updateShowQRWidgetSelection(true)
      this.updateSelectedQRWidget(null)
    },
    onShowGptConnect() {
      this.updateCurrentTab('gpt-connect')
      this.updateShowGptWidgetSelection(true)
      this.updateSelectedGptWidget(null)
    },
    onAddLanguage() {
      this.showLanguageModal = true
    },
    onCloseLanguageModal() {
      this.showLanguageModal = false
    },
    hasClass(el, className) {
      if (el.classList) return el.classList.contains(className)
      return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`))
    },
    addClass(el, className) {
      if (el.classList) el.classList.add(className)
      else if (!this.hasClass(el, className)) el.className += ` ${className}`
    },
    removeClass(el, className) {
      if (el.classList) el.classList.remove(className)
      else if (this.hasClass(el, className)) {
        const reg = new RegExp(`(\\s|^)${className}(\\s|$)`)
        el.className = el.className.replace(reg, ' ')
      }
    }
  },
  watch: {
    showDeleteTranslationModal() {
      this.showDeletePopup = this.showDeleteTranslationModal
    },
    showResetTranslationModal() {
      this.showResetPopup = this.showResetTranslationModal
    },
    showDeletePopup() {
      if (this.showDeletePopup !== this.showDeleteTranslationModal) {
        this.updateShowDeleteTranslationModal(this.showDeletePopup)
      }
    },
    showResetPopup() {
      if (this.showResetPopup !== this.showResetTranslationModal) {
        this.updateShowResetTranslationModal(this.showResetPopup)
      }
    },
    addKey() {
      const data = {
        companyId: this.activeUserInfo.company,
        version: this.$firebase.firestore.FieldValue.increment(1),
        updated: dayjs().utc().toDate()
      }
      if (['appointment-booking', 'button-and-hints', 'email-translations'].includes(this.currentTranslationTab)) {
        this.$db.collection('translations').doc(this.activeUserInfo.company).set(data)
        for (const translation of this.translations.filter((x) => x.updateInDb)) {
          this.$db.collection('translations').doc(this.activeUserInfo.company).collection('languages').doc(translation.ln).set(translation)
        }
      }
    },
    async 'updateKey.key'() {
      try {
        const data = {
          companyId: this.activeUserInfo.company,
          updated: dayjs().utc().toDate()
        }

        if (['appointment-booking', 'button-and-hints', 'email-translations'].includes(this.updateKey.tab)) {
          await this.$db.collection('translations').doc(this.activeUserInfo.company).set(data)

          for (const translation of this.translations.filter((x) => x.updateInDb)) {
            translation.updateInDb = false
            const updated = {
              canBeDeleted: translation.canBeDeleted || false,
              ln: translation.ln,
              lnFullText: translation.lnFullText,
              lnText: translation.lnText,
              modified: dayjs().utc().toDate(),
              sort: translation.sort
            }

            switch (this.updateKey.tab) {
              case 'appointment-booking':
                updated.appointmentBooking = translation.appointmentBooking
                break
              case 'button-and-hints':
                updated.buttonAndHints = translation.buttonAndHints
                break
              case 'email-translations':
                updated.emailTranslations = translation.emailTranslations
                break
            }
            await this.$db.collection('translations').doc(this.activeUserInfo.company).collection('languages').doc(translation.ln).set(updated, { merge: true })
          }

          this.updateField(this.updateKey.updatedField)
        }

        if (this.updateKey.tab === 'web-connect') {
          await this.$db
            .collection('translations')
            .doc(this.activeUserInfo.company)
            .collection('web-connect')
            .doc(this.selectedWidget)
            .set(data, { merge: true })
          for (const translation of this.translations.filter((x) => x.updateInDb && x.webConnect)) {
            translation.updateInDb = false

            const updated = {
              webConnect: translation.webConnect,
              canBeDeleted: translation.canBeDeleted,
              ln: translation.ln,
              lnFullText: translation.lnFullText,
              lnText: translation.lnText,
              modified: dayjs().utc().toDate(),
              sort: translation.sort
            }
            await this.$db
              .collection('translations')
              .doc(this.activeUserInfo.company)
              .collection('web-connect')
              .doc(this.selectedWidget)
              .collection('languages')
              .doc(translation.ln)
              .set(updated, { merge: true })

            const mappedField = this.webconnectMapping[this.updateKey.updatedField]
            if (translation.ln === 'en' && mappedField) {
              if (mappedField.type === 'object') {
                const widgetRef = await this.$db.collection('dialogs').doc(this.selectedWidget).get()
                const widget = widgetRef.data()
                if (widget && mappedField.object === 'contactForm') {
                  const updateWC = widget.contactForm
                  updateWC[mappedField.field] = updated.webConnect[mappedField.translationField]
                  await this.$db.collection('dialogs').doc(this.selectedWidget).set({ contactForm: updateWC }, { merge: true })
                }
                if (widget && mappedField.object === 'sessionEndScreen') {
                  const updateWC = widget.sessionEndScreen
                  updateWC[mappedField.field] = updated.webConnect[mappedField.translationField]
                  await this.$db.collection('dialogs').doc(this.selectedWidget).set({ sessionEndScreen: updateWC }, { merge: true })
                }
                if (widget && mappedField.object === 'vega') {
                  const updateWC = widget.vega
                  updateWC[mappedField.field] = updated.webConnect[mappedField.translationField]
                  await this.$db.collection('dialogs').doc(this.selectedWidget).set({ vega: updateWC }, { merge: true })
                }
              } else {
                const updateWC = {}
                updateWC[mappedField.field] = updated.webConnect[mappedField.translationField]
                await this.$db.collection('dialogs').doc(this.selectedWidget).set(updateWC, { merge: true })
              }
              if (this.selectedWidgetSavedTranslation && this.selectedWidgetSavedTranslation.widgetId === this.selectedWidget) {
                const obj = {
                  webConnect: updated.webConnect,
                  widgetId: this.selectedWidget
                }
                this.updateSelectedWidgetSavedTranslation(obj)
              }
            }
          }

          this.updateField(this.updateKey.updatedField)
        }

        if (this.updateKey.tab === 'gpt-connect') {
          await this.$db
            .collection('translations')
            .doc(this.activeUserInfo.company)
            .collection('gpt-connect')
            .doc(this.selectedGptWidget)
            .set(data, { merge: true })
          for (const translation of this.translations.filter((x) => x.updateInDb && x.gptConnect)) {
            translation.updateInDb = false

            const updated = {
              gptConnect: translation.gptConnect,
              canBeDeleted: translation.canBeDeleted,
              ln: translation.ln,
              lnFullText: translation.lnFullText,
              lnText: translation.lnText,
              modified: dayjs().utc().toDate(),
              sort: translation.sort
            }
            await this.$db
              .collection('translations')
              .doc(this.activeUserInfo.company)
              .collection('gpt-connect')
              .doc(this.selectedGptWidget)
              .collection('languages')
              .doc(translation.ln)
              .set(updated, { merge: true })

            const mappedField = this.gptconnectMapping[this.updateKey.updatedField]
            if (translation.ln === 'en' && mappedField) {
              if (mappedField.type === 'object') {
                const widgetRef = await this.$db.collection('dialogs').doc(this.selectedGptWidget).get()
                const widget = widgetRef.data()
                if (widget && mappedField.object === 'gpt') {
                  const updateWC = widget.gpt
                  updateWC[mappedField.field] = updated.gptConnect[mappedField.translationField]
                  await this.$db.collection('dialogs').doc(this.selectedGptWidget).set({ gpt: updateWC }, { merge: true })
                }
              } else {
                const updateWC = {}
                updateWC[mappedField.field] = updated.gptConnect[mappedField.translationField]
                await this.$db.collection('dialogs').doc(this.selectedGptWidget).set(updateWC, { merge: true })
              }
              if (this.selectedGptWidgetSavedTranslation && this.selectedGptWidgetSavedTranslation.widgetId === this.selectedGptWidget) {
                const obj = {
                  gptConnect: updated.gptConnect,
                  widgetId: this.selectedGptWidget
                }
                this.updateSelectedGptWidgetSavedTranslation(obj)
              }
            }
          }

          this.updateField(this.updateKey.updatedField)
        }

        if (this.updateKey.tab === 'qr-connect') {
          await this.$db
            .collection('translations')
            .doc(this.activeUserInfo.company)
            .collection('qr-connect')
            .doc(this.selectedQRWidget)
            .set(data, { merge: true })
          for (const translation of this.translations.filter((x) => x.updateInDb && x.qrConnect)) {
            translation.updateInDb = false

            const updated = {
              qrConnect: translation.qrConnect,
              canBeDeleted: translation.canBeDeleted,
              ln: translation.ln,
              lnFullText: translation.lnFullText,
              lnText: translation.lnText,
              modified: dayjs().utc().toDate(),
              sort: translation.sort
            }
            await this.$db
              .collection('translations')
              .doc(this.activeUserInfo.company)
              .collection('qr-connect')
              .doc(this.selectedQRWidget)
              .collection('languages')
              .doc(translation.ln)
              .set(updated, { merge: true })

            const mappedField = this.qrconnectMapping[this.updateKey.updatedField]
            if (translation.ln === 'en' && mappedField) {
              if (mappedField.type === 'object') {
                const widgetRef = await this.$db.collection('campaigns').doc(this.selectedQRWidget).get()
                const widget = widgetRef.data()
                if (widget && mappedField.object === 'contactForm') {
                  const updateQC = widget.contactForm
                  updateQC[mappedField.field] = updated.qrConnect[mappedField.translationField]
                  await this.$db.collection('campaigns').doc(this.selectedQRWidget).set({ contactForm: updateQC }, { merge: true })
                }
                if (widget && mappedField.object === 'sessionEndScreen') {
                  const updateQC = widget.sessionEndScreen
                  updateQC[mappedField.field] = updated.qrConnect[mappedField.translationField]
                  await this.$db.collection('campaigns').doc(this.selectedQRWidget).set({ sessionEndScreen: updateQC }, { merge: true })
                }
              } else {
                const updateQC = {}
                updateQC[mappedField.field] = updated.qrConnect[mappedField.translationField]
                await this.$db.collection('campaigns').doc(this.selectedQRWidget).set(updateQC, { merge: true })
              }
              if (this.selectedQRWidgetSavedTranslation && this.selectedQRWidgetSavedTranslation.widgetId === this.selectedQRWidget) {
                const obj = {
                  qrConnect: updated.qrConnect,
                  widgetId: this.selectedQRWidget
                }
                this.updateSelectedQRWidgetSavedTranslation(obj)
              }
            }
          }

          this.updateField(this.updateKey.updatedField)
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
    },
    async removedLanguage() {
      if (this.removedLanguage) {
        const removedLanguage = this.removedLanguage.split('_')[0]
        const translation = this.translations.find((x) => x.ln === removedLanguage)
        if (
          translation &&
          ((translation.appointmentBooking &&
            Object.keys(translation.appointmentBooking).length > 0 &&
            !translation.appointmentBooking.__isCopiedFromDefault) ||
            (translation.buttonAndHints && Object.keys(translation.buttonAndHints).length > 0 && !translation.buttonAndHints.__isCopiedFromDefault) ||
            (translation.emailTranslations && Object.keys(translation.emailTranslations).length > 0 && !translation.emailTranslations.__isCopiedFromDefault))
        ) {
          await this.$db
            .collection('translations')
            .doc(this.activeUserInfo.company)
            .collection('languages')
            .doc(removedLanguage)
            .set(
              {
                appointmentBooking:
                  translation.appointmentBooking && Object.keys(translation.appointmentBooking).length > 0 ? translation.appointmentBooking : null,
                buttonAndHints: translation.buttonAndHints && Object.keys(translation.buttonAndHints).length > 0 ? translation.buttonAndHints : null,
                emailTranslations:
                  translation.emailTranslations && Object.keys(translation.emailTranslations).length > 0 ? translation.emailTranslations : null,
                canBeDeleted: translation.canBeDeleted,
                ln: translation.ln,
                lnFullText: translation.lnFullText,
                lnText: translation.lnText
              },
              { merge: true }
            )
        } else {
          await this.$db.collection('translations').doc(this.activeUserInfo.company).collection('languages').doc(removedLanguage).delete()
        }
        this.updateTranslatonUpdateKey()
      }
    },
    updatedField() {
      if (this.updatedField) {
        const inputDivElement = document.getElementById(`body-box-${this.updatedField}`)
        if (inputDivElement) {
          this.addClass(inputDivElement, 'flash-t')
          setTimeout(() => {
            this.removeClass(inputDivElement, 'flash-t')
            this.updateField(null)
          }, 400)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.translations {
  .modal-container {
    position: relative;
    padding: 0px !important;
  }
}
.translations-button-add .vs-button--icon {
  z-index: 1;
}
</style>
